<template>
  <div id="app">
    <div class="app-c">
      <header class="index_hader">
        <router-link to="/">
          <div class="logo"></div>
        </router-link>
        <nav>
          <ul>
            <router-link to="/">
              <li>Home</li>
            </router-link>
            <router-link to="/about">
              <li>About</li>
            </router-link>
            <router-link to="/case">
              <li>Case Studies</li>
            </router-link>
            <router-link to="/team">
              <li>Team</li>
            </router-link>
            <router-link to="/culture">
              <li>Vision</li>
            </router-link>
            <a
              target="_blank"
              href="https://www.zhipin.com/gongsir/b4b8c715ba65a4581nN-2du1FQ~~.html?ka=job-detail-company_custompage"
            >
              <li>Join Us</li>
            </a>
            <router-link to="/address">
              <li>Contact Us</li>
            </router-link>
            <a href="https://forcartech.cn/#/"><li>Chinese</li></a>
          </ul>
        </nav>
        <!-- add -->
        <el-button
          class="drawer-top"
          @click="drawer = true"
          icon="el-icon-more"
        ></el-button>
        <!-- add -->
      </header>
      <transition name="fade">
        <router-view></router-view>
      </transition>
      <div class="bottom-information">
        <div class="bottom-data">
          <div class="bottom-data-li"></div>
          <div class="bottom-data-li">
            <div class="bottom-data-data">
              <div class="bottom-data-li-data">
                <div class="li-data-u">
                  <ul>
                    <li class="title">About Us</li>
                    <router-link to="/about">
                      <li>Company Profile</li>
                    </router-link>
                    <router-link to="/case">
                      <li>Project Cases</li>
                    </router-link>
                    <li>Patent Certificate</li>
                  </ul>
                </div>
              </div>
              <div class="bottom-data-li-data">
                <div class="li-data-u">
                  <ul>
                    <li class="title">Join Us</li>
                    <li>Zhaopin Recruitment</li>
                    <a
                      target="_blank"
                      href="https://www.zhipin.com/gongsir/b4b8c715ba65a4581nN-2du1FQ~~.html?ka=job-detail-company_custompage"
                    >
                      <li>Boss Direct Recruitment</li>
                    </a>
                  </ul>
                </div>
              </div>
              <div class="bottom-data-li-data">
                <div class="li-data-u">
                  <ul>
                    <li class="title">Contact Us</li>
                    <el-tooltip
                      class="item"
                      effect="light"
                      content="13807121650"
                      placement="right"
                    >
                      <li>Service Line</li>
                    </el-tooltip>
                    <router-link to="/address">
                      <li>Company Address</li>
                    </router-link>
                    <router-link to="/address">
                      <li>Business Cooperation</li>
                    </router-link>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-data-li">
            <div class="ma"><img src="./assets/img/code.webp" alt="" /></div>
            <p>Scan the QR code to follow Forcartech official account</p>
          </div>
        </div>
      </div>
    </div>
    <!-- add -->
    <el-drawer
      class="drawerBox"
      :modal="false"
      title=""
      :visible.sync="drawer"
      :direction="direction"
    >
      <ul>
        <router-link to="/" @click.native="handleClickClose">
          <li>Home</li>
        </router-link>
        <router-link to="/about" @click.native="handleClickClose">
          <li>About</li>
        </router-link>
        <router-link to="/case" @click.native="handleClickClose">
          <li>Case Studies</li>
        </router-link>
        <router-link to="/team" @click.native="handleClickClose">
          <li>Team</li>
        </router-link>
        <router-link to="/culture" @click.native="handleClickClose">
          <li>Vision</li>
        </router-link>
        <a
          target="_blank"
          href="https://www.zhipin.com/gongsir/b4b8c715ba65a4581nN-2du1FQ~~.html?ka=job-detail-company_custompage"
          @click="handleClickClose"
        >
          <li>Join Us</li>
        </a>
        <router-link to="/address" @click.native="handleClickClose">
          <li>Contact Us</li>
        </router-link>
        <a href="https://forcartech.cn/#/" @click="handleClickClose"
          ><li>Chinese</li></a
        >
      </ul>
    </el-drawer>
    <!-- add -->
    <el-backtop style="background-color: #00000000" :bottom="100" :right="12">
      <div
        style="
          height: 100%;
          width: 100%;
          background-color: #ff0000;
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
          text-align: center;
          color: #fff;
          border-radius: 8px;
        "
      >
        <img src="./assets/img/top02.svg" alt="" />
      </div>
    </el-backtop>
  </div>
</template>
<script>
export default {
  data() {
    return {
      direction: "ttb",
      drawer: false,
    };
  },
  methods: {
    handleClickClose() {
      this.drawer = false;
    },
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  user-select: none;
  font-family: "PFSC S";
}

.el-image-viewer__btn {
  display: none !important;
}

a {
  text-decoration: none;
}

.app-c {
  max-width: 2160px;
  margin: 0 auto;
}

html body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

.p1 {
  min-width: 1150px;
  margin-top: 70px;
  font-size: 40px;

  .col {
    color: var(--red-color);
  }

  span {
    display: inline-block;
    letter-spacing: 5px;
    margin-left: 5px;
  }
}

.p2 {
  min-width: 1150px;
  margin: 0;
  margin-top: 17px;
}

// 页面切换过渡效果
.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

//add
.drawerBox {
  ul {
    padding: 0;
    margin: 0;
    a {
      text-decoration: none;
      color: inherit;
      li {
        list-style: none;
        margin-top: 15px;
        color: #fff;
        font-size: 18px;
      }
    }
  }
}
//add
.el-drawer {
  height: 50% !important;
  background: rgba(0, 0, 0, 0.5) !important;
  backdrop-filter: blur(0.808333rem) !important;
}
//add
.el-drawer__header {
  margin-bottom: 20px !important;
}
//add
.router-link-exact-active {
  @media (max-width: 768px) {
    position: relative;
    .logo {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      // width: 80px !important;
    }
  }
}
//add

.index_hader {
  width: 100%;
  max-width: 2160px;
  min-width: 1150px;
  height: 60px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: saturate(150%) blur(0.808333rem);
  position: fixed;
  top: 0;
  z-index: 99;
  border-bottom: 1px solid rgba(233, 231, 231, 0.6);
  //add
  .drawer-top {
    display: none;
    position: absolute;
    right: 10px;
    height: 100%;
    width: 30px;
    padding: 0;
    margin: 0;
    border: 0;
    color: #ddd1d1;
    background-color: rgba(0, 0, 0, 0);

    &:focus,
    :hover {
      color: #ddd1d1;
      border: 0;
      background-color: rgba(0, 0, 0, 0);
    }
    @media (max-width: 768px) {
      display: inline-block;
    }
  }
  //add

  .logo {
    margin-left: 15px;
    width: 160px;
    height: 100%;
    float: left;
    background-image: url("./assets/img/LOGO.svg");
    background-size: 98%;
    background-position: center;
    background-repeat: no-repeat;
    //add
    @media (max-width: 768px) {
      width: 80px;
    }
    //add
  }

  nav {
    height: 100%;
    width: 1100px;
    float: right;
    margin-right: 120px;
    //add
    @media (max-width: 768px) {
      display: none;
    }
    //add

    ul {
      width: 100%;
      height: 100%;
      display: block;
      display: flex;
      margin: 0;
      justify-content: space-around;

      li {
        position: relative;
        display: inline-block;
        width: 150px;
        line-height: 40px;
        color: aliceblue;
        padding: 10px;
        @media (max-width: 768px) {
          width: 35px;
        }

        // background-color:red;
        &::before {
          content: "";
          width: 0;
          height: 2px;
          background-color: #d53434;
          position: absolute;
          left: 0;
          bottom: 0;
          transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          @media (max-width: 768px) {
            display: none;
          }
        }

        &:hover {
          cursor: pointer;

          &::before {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .index_hader {
    max-width: auto;
    min-width: auto;
    display: flex;
    height: 50px;

    // .logo {
    //   margin-top: -3px;
    //   margin-left: 0;
    //   width: 80px;
    // }

    nav {
      ul {
        width: 300px;
        padding-left: 0;

        li {
          line-height: 8px;
          font-size: 6px;
        }
      }
    }
  }
}

.bottom-information {
  width: 100%;
  min-width: 1920px;
  height: 220px;
  background-image: url("@/assets/img/页脚内容2.svg");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;

  //页脚高度
  @media (max-width: 786px) {
    display: none;
    height: 90px;
  }

  .bottom-data {
    width: 1150px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;

    .bottom-data-li {
      width: auto;
      height: 100%;
      flex: 1;

      .bottom-data-data {
        display: flex;
        justify-content: space-between;
        width: 52%;
        float: right;
        margin-right: 120px;
        margin-top: 10px;

        //关于我们...  确定位置
        @media (max-width: 768px) {
          margin-right: 15px;
          margin-top: -1px;
        }

        .li-data-u {
          width: 100%;
          ul {
            display: block;
            width: 100%;
            margin: 0;
            list-style: none;

            @media (max-width: 768px) {
              padding: 0;
            }

            li {
              margin-top: 20px;
              color: #b1b1b1;
              font-size: 12px;
              cursor: pointer;

              @media (max-width: 768px) {
                margin-top: 3px;
                font-size: 8px;
              }

              &:hover {
                color: #f5f5f5;
              }
            }

            .title {
              color: #f5f5f5;
            }
          }
        }
      }

      &:nth-child(2) {
        flex: 3;
      }

      &:nth-child(3) {
        .ma {
          width: 100px;
          height: 100px;
          margin: 30px auto;
          margin-bottom: 10px;
          background: #d9d9db;

          //二维码位置调节
          @media (max-width: 768px) {
            width: 40px;
            height: 40px;
            margin: 8px 10px 5px 15px;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }

        p {
          color: #b1b1b1;
          font-size: 12px;

          //二维码下方字体大小调节
          @media (max-width: 768px) {
            font-size: 8px;
          }
        }
      }
    }
  }

  //去除页脚盒子整体的边距
  @media (max-width: 768px) {
    .bottom-data {
      width: 100%;
      margin: 0;
    }
  }
}

//页脚移动端适配
@media (max-width: 768px) {
  .bottom-information {
    min-width: auto;
    background-size: 200%;
    background-position: 35%;
  }
}

// 修改图片预览放大后的默认大小
.el-image-viewer__wrapper .el-image-viewer__canvas .el-image-viewer__img {
  transform: scale(0.8) rotate(0deg) !important;
}

/* 设置滚动条的宽度和颜色 */
::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: #f5f5f5;
}

/* 设置滑块的颜色 */
::-webkit-scrollbar-thumb {
  background-color: #ff0000;
}

/* 设置滑道上未被滑块覆盖部分的颜色 */
::-webkit-scrollbar-track {
  background-color: #d9d9db;
}
</style>
