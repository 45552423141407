<template>
  <div class="home-container">
    <div class="main">
      <div class="top-img">
        <img
          class="img-top"
          src="../assets/img/首页-顶图.webp"
          @load="imageLoaded = true"
          alt=""
        />
      </div>
      <div class="main-data" v-if="imageLoaded">
        <div class="p2 p2-title">
          <span class="p2-col">Our mission</span>
          <span class="p2-col-1"
            >is to establish ourselves as a premier global leader in automotive
            technology, excelling in the creation and innovation of technical
            writing, special tools and computer software for exceptional
            after-sales service.</span
          >
          <p class="p2-col-1">
            We are committed to setting new standards in research, development,
            and production, propelling the automotive industry forward with our
            cutting-edge solutions.
          </p>
        </div>
        <!-- 第一部分内容 -->

        <div class="main-ability1">
          <!-- 企业资质 -->
          <div class="ablity-li">
            <div class="ablity-li-img">
              <img src="../assets/img/专利/高新企业证书.webp" alt="" />
            </div>
            <div class="ablity-bot">
              <div class="ablity-bot-text">
                <p class="text1">High-tech Certification</p>
                <p class="text2">
                  We are honored to be recognized on the Hubei Province' s
                  official list of high-tech enterprise, having achieved the
                  esteemed High-tech Enterprise Certification.
                </p>
              </div>
            </div>
          </div>
          <!-- 多项专利 -->
          <div class="ablity-li">
            <div class="ablity-li-img">
              <img src="../assets/img/专利/专利证书.webp" alt="" />
            </div>
            <div class="ablity-bot">
              <div class="ablity-bot-text">
                <p class="text1">Multiple Patents</p>
                <p class="text2">
                  Years of hard work and innovation have resulted in multiple
                  patents and software copyrights.
                </p>
              </div>
            </div>
          </div>
          <!-- 管理体系 -->
          <div class="ablity-li">
            <div class="ablity-li-img">
              <img
                class="img-3"
                src="../assets/img/专利/IOS9001认证.webp"
                alt=""
              />
            </div>
            <div class="ablity-bot">
              <div class="ablity-bot-text">
                <p class="text1">ISO9001 Certifications</p>
                <p class="text2">
                  We have been consecutively certified as an ISO 9001 quality
                  management systems company for 10 years.
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- 第五部分动态展示 -->
        <div class="main-ability5">
          <!-- <iframe class="baniframe" sandbox="allow-scripts allow-same-origin" id="mh" src="./index.html"></iframe> -->
          <div class="ability5-top">
            <p class="text1">Our Latest Successful Projects</p>
            <p class="text2">Tell stories with real cases</p>
          </div>
          <div class="ability5-main">
            <div class="ability5-main-li">
              <div class="ability5-img">
                <img src="../assets/img/1.webp" alt="" />
                <div class="ability5-btn">
                  <img
                    src="../assets/img/播放.png"
                    alt=""
                    @click="openDialog(0)"
                  />
                </div>
              </div>

              <div class="ability5-text">
                <p class="text1">3D CG animation</p>
                <p class="text2">Yingche Technology</p>
              </div>
            </div>

            <div class="ability5-main-li">
              <div class="ability5-img">
                <img src="../assets/img/2.webp" alt="" />
                <div class="ability5-btn">
                  <img
                    src="../assets/img/播放.png"
                    alt=""
                    @click="openDialog(1)"
                  />
                </div>
              </div>

              <div class="ability5-text">
                <p class="text1">3D CG animation</p>
                <p class="text2">ZEEKR 001</p>
              </div>
            </div>

            <div class="ability5-main-li">
              <div class="ability5-img">
                <img src="../assets/img/3.webp" alt="" />
                <div class="ability5-btn">
                  <img
                    src="../assets/img/播放.png"
                    alt=""
                    @click="openDialog(2)"
                  />
                </div>
              </div>

              <div class="ability5-text">
                <p class="text1">
                  3D CG and Realistic Shooting blended Instructional Video
                </p>
                <p class="text2">NEW 2024 JMC Grand Avenue Raptor 2.3L Turbo</p>
              </div>
            </div>

            <div class="ability5-main-li">
              <div class="ability5-img">
                <img src="../assets/img/4.webp" alt="" />
                <div class="ability5-btn">
                  <img
                    src="../assets/img/播放.png"
                    alt=""
                    @click="openDialog(3)"
                  />
                </div>
              </div>

              <div class="ability5-text">
                <p class="text1">Instructional Video Production</p>
                <p class="text2">NEW 2024 JMC Grand Avenue Raptor 2.3L Turbo</p>
              </div>
            </div>

            <div class="ability5-main-li">
              <div class="ability5-img">
                <img src="../assets/img/6.webp" alt="" />
                <div class="ability5-btn">
                  <img
                    src="../assets/img/播放.png"
                    alt=""
                    @click="openDialog(4)"
                  />
                </div>
              </div>

              <div class="ability5-text">
                <p class="text1">Instruction Video Filming</p>
                <p class="text2">VOYAH FREE</p>
              </div>
            </div>

            <!-- <div class="ability5-main-li">
              <div class="ability5-img">
                <img src="../assets/img/6.webp" alt="" />
                <div class="ability5-btn">
                  <img
                    src="../assets/img/播放.png"
                    alt=""
                    @click="openDialog(5)"
                  />
                </div>
              </div>

              <div class="ability5-text">
                <p class="text1">实拍视频</p>
                <p class="text2">东风本田</p>
              </div>
            </div> -->
          </div>
          <div class="ability5-bot">
            <div class="p1">
              <span>Enhanced User Experience with Interactive 3D</span>
            </div>
            <p class="p2-title">
              "Our latest small-sized dynamic and engaging presentations or
              displays leverage 3D graphics and interactive, enabling users to
              explore, manipulate, and interact with content in a
              three-dimensional environment,offering an immersive and
              captivating experience."
            </p>
          </div>

          <div class="box-3d">
            <div class="btn-3dbox">
              <a
                href="https://neweinstruction.jmc.com.cn/book/jp360/3d/JMC_che/JMC_che.html"
                target="_blank"
              >
                <div class="dbtn-box btn-box1">
                  <div class="bl"></div>
                  <img src="../assets/img/3D卡片-宽1-透(3).webp" alt="" />
                </div>
              </a>
              <a href="./3d/动力原理/动力原理.html" target="_blank">
                <div class="dbtn-box btn-box2">
                  <div class="bl"></div>
                  <img src="../assets/img/3D卡片-宽2-透(4).webp" alt="" />
                </div>
              </a>

              <a href="#" target="_blank">
                <div class="dbtn-box btn-box3">
                  <div class="bl"></div>
                  <img src="../assets/img/3D卡片-宽3-透(3).webp" alt="" />
                </div>
              </a>
            </div>
            <Three2 :top="0" class="Three2"> </Three2>
          </div>
        </div>
        <!-- 第二部分内容 -->
        <div class="main-ability2-w">
          <div class="p1">
            <span>Case Studies</span>
          </div>
          <p class="p2">Weaving a narrative with our products</p>

          <div class="main-ability2">
            <div class="ablity2-data">
              <!-- 用户手册 -->
              <div class="ablity2-data-li" @click="navigateToTarget(0)">
                <div class="ab2-img">
                  <img src="../assets/img/手册封面/相关案例1.webp" alt="" />
                </div>
                <div class="ablity2-bot">
                  <div class="ablity2-bot-text">
                    <p class="text1">User Manual</p>
                    <p class="text2">
                      Developed "User Manuals" for a total of 86 vehicle models
                    </p>
                  </div>
                  <i class="el-icon-right"></i>
                </div>
              </div>
              <!-- 维修手册 -->
              <div class="ablity2-data-li" @click="navigateToTarget(1)">
                <div class="ab2-img">
                  <img src="../assets/img/手册封面/相关案例2.webp" alt="" />
                </div>
                <div class="ablity2-bot">
                  <div class="ablity2-bot-text">
                    <p class="text1">Maintenance Manual</p>
                    <p class="text2">
                      Developed "Maintenance Manual" for a total of 64 models
                    </p>
                  </div>
                  <i class="el-icon-right"></i>
                </div>
              </div>
              <!-- 拆解手册 -->
              <div class="ablity2-data-li" @click="navigateToTarget(4)">
                <div class="ab2-img">
                  <img src="../assets/img/手册封面/相关案例3.webp" alt="" />
                </div>
                <div class="ablity2-bot">
                  <div class="ablity2-bot-text">
                    <p class="text1">Disassembly Manual</p>
                    <p class="text2">
                      We have developed comprehensive Disassembly Manuals for a
                      total of 17 vehicle models.
                    </p>
                  </div>
                  <i class="el-icon-right"></i>
                </div>
              </div>
            </div>
            <div class="ablity2-data">
              <!-- 工时手册 -->
              <div class="ablity2-data-li" @click="navigateToTarget(5)">
                <div class="ab2-img">
                  <img src="../assets/img/手册封面/相关案例4.webp" alt="" />
                </div>
                <div class="ablity2-bot">
                  <div class="ablity2-bot-text">
                    <p class="text1">Working Hours Manual</p>
                    <p class="text2">
                      We have developed Working Hours Manuals for a total of 17
                      vehicle models.
                    </p>
                  </div>
                  <i class="el-icon-right"></i>
                </div>
              </div>
              <!-- 专用工具-->
              <div class="ablity2-data-li" @click="navigateToTarget(6)">
                <div class="ab2-img">
                  <img src="../assets/img/手册封面/相关案例5.webp" alt="" />
                </div>
                <div class="ablity2-bot">
                  <div class="ablity2-bot-text">
                    <p class="text1">Specialty Tools</p>
                    <p class="text2">
                      We have developed Specialty Tools for a total of 17
                      vehicle models.
                    </p>
                  </div>
                  <i class="el-icon-right"></i>
                </div>
              </div>
              <!-- 培训教材教具 -->
              <div class="ablity2-data-li" @click="navigateToTarget(7)">
                <div class="ab2-img">
                  <img src="../assets/img/手册封面/相关案例6.webp" alt="" />
                </div>
                <div class="ablity2-bot">
                  <div class="ablity2-bot-text">
                    <p class="text1">Training Materials and Teaching Aids</p>
                    <p class="text2">
                      We have developed Training Materials and Teaching Aids for
                      a total of 17 vehicle models.
                    </p>
                  </div>
                  <i class="el-icon-right"></i>
                </div>
              </div>
            </div>
          </div>

          <!-- 联系我们 -->
          <div class="main-ability3">
            <div class="left-box">
              <div class="ability3-left">
                <div class="mask-1">
                  <img src="../assets/img/信用中国/信用中国2.png" alt="" />
                </div>
                <div class="mask-2">
                  <img src="../assets/img/信用中国/信用中国1.png" alt="" />
                </div>
              </div>
            </div>
            <div class="ability3-right">
              <h3>
                Interview with Credit China CCTV, Feng Chen:One-Stop Service For
                Automotive After-Sale Technology.
              </h3>
              <div class="right-desc">
                <div class="red-box">
                  <span class="red-font">Original</span>
                  <span class="hui-font">Credit China Column</span>
                </div>
                <div class="hui-time">2024/03/04</div>
              </div>
              <p class="font-desc">
                With the rapid evolution of the antomotive industry, consumers'
                demands for vehicle quality, performance,and services are
                continuously increasing. Against this backdrop, car
                manufacturers and dealerships must provide professional
                automotive technical services and innovative marketing
                strategies to maintain a competitve edge in the fierce market
                competition.Amidst this wave of change,Mr. Chen, With his
                profound industy experience an forward-looking vision,has led
                Wuhan ForCarTech Automotive Technology Co., Ltd. to the
                forefront of the industry.
              </p>
              <div class="bgc-hui">
                <div class="red-animation"></div>
              </div>
              <div class="red-href">
                <a
                  href="https://book.cctv.com/2024/04/04/VIDEaYwcqBBh1tK7SfErECPK240404.shtml"
                  target="_blank"
                  >details →</a
                >
              </div>
            </div>
          </div>
        </div>

        <!-- 第三部分内容 -->
        <div class="p1">
          <span>Service Process</span>
        </div>
        <!-- <p class="p2">Service Process</p> -->
        <div class="main-ability4"></div>

        <div class="p1 fw-p1">
          <span>Business</span><span class="col">Philosophy</span>
        </div>
        <p class="p2">
          Embracing our core business philosophy of "serving with heart and
          ensuring results," we are committed to delivering professional and
          efficient service to our customers.
        </p>

        <!-- 第七部分 -->
        <div class="main-ability7-c">
          <div class="main-ability7">
            <img src="../assets/img/优势.webp" alt="" />
          </div>
        </div>
        <!-- 第八部分 -->
        <div class="ain-ability8-w">
          <div class="p1">
            <span>Business Partners</span>
          </div>
          <!-- <p class="p2">Business Parters</p> -->
          <div class="main-ability8">
            <div class="ability8-list">
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-1.webp" alt="" />
              </div>
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-2.webp" alt="" />
              </div>
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-3.webp" alt="" />
              </div>
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-4.webp" alt="" />
              </div>
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-15.webp" alt="" />
              </div>
            </div>
            <div class="ability8-list">
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-6.webp" alt="" />
              </div>
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-7.webp" alt="" />
              </div>
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-8.webp" alt="" />
              </div>
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-9-1.webp" alt="" />
              </div>
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-9-2.webp" alt="" />
              </div>
            </div>
            <div class="ability8-list">
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-19.webp" alt="" />
              </div>
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-20.webp" alt="" />
              </div>
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-21.webp" alt="" />
              </div>
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-22.webp" alt="" />
              </div>
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-23.webp" alt="" />
              </div>
            </div>
            <div class="ability8-list">
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-9-3.webp" alt="" />
              </div>
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-11.webp" alt="" />
              </div>
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-12.webp" alt="" />
              </div>
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-13.webp" alt="" />
              </div>
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-14.webp" alt="" />
              </div>
            </div>
            <div class="ability8-list">
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-10.webp" alt="" />
              </div>
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-16.webp" alt="" />
              </div>
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-17.webp" alt="" />
              </div>
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-18.webp" alt="" />
              </div>
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/客户logo-5.webp" alt="" />
              </div>
            </div>

            <div class="ability8-list">
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/学校logo-1.webp" alt="" />
              </div>
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/学校logo-2.webp" alt="" />
              </div>
              <div class="ability8-logo">
                <img src="../assets/img/客户logo/学校logo-3.webp" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      @close="closeDialog"
      :visible.sync="outerVisible"
      :show-close="false"
    >
      <video
        ref="videoPlayer"
        controlsList="nodownload"
        :src="videoUrl"
        controls
      ></video>
    </el-dialog>
  </div>
</template>

<script>
import Three2 from "@/components/Three2.vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "HomeView",
  data() {
    return {
      outerVisible: false,
      innerVisible: false,
      videoUrl: ``,
      videoUrls: [
        `./video/1.mp4`,
        `./video/2-CG动画.mp4`,
        `./video/3.mp4`,
        `./video/4.mp4`,
        `./video/5.mp4`,
      ],
      imageLoaded: false,
      animations: [
        // 动画列表，每个元素对应一个动画
        {
          selector: ".p1",
          threshold: 50, // 滚动位置达到 300px 时播放动画
          isActive: false, // 标识动画是否正在播放
          animation: [
            {
              x: 50,
              opacity: 1,
              duration: 1.5, // 动画时间，默认为1
            },
          ],
        },
        {
          selector: ".p2",
          threshold: 200, // 滚动位置达到 500px 时播放动画
          isActive: false,
          animation: [
            {
              x: 50,
              opacity: 1,
            },
          ],
        },
        {
          selector: ".main-ability1",
          threshold: 300, // 滚动位置达到 700px 时播放动画
          isActive: false,
          animation: [
            {
              x: 50,
              opacity: 1,
            },
          ],
        },
      ],
    };
  },
  components: {
    Three2,
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    const element1 = document.querySelector(".p1");
    const element2 = document.querySelector(".p2");
    const element3 = document.querySelector(".main-ability1");
    gsap.from(element1, {
      opacity: 0,
      duration: 1,
      scrollTrigger: {
        trigger: element1,
        start: "top 80%",
        end: "top 20%",
        toggleActions: "play none none reset",
        scrub: true,
      },
    });
    gsap.from(element2, {
      opacity: 0,
      duration: 1,
      scrollTrigger: {
        trigger: element2,
        start: "top 80%",
        end: "top 20%",
        toggleActions: "play none none reset",
        scrub: true,
      },
    });
    gsap.from(element3, {
      y: 100, // 设置元素起始位置的垂直偏移
      opacity: 0, // 设置元素起始时的透明度
      duration: 1, // 动画持续时间
      scrollTrigger: {
        trigger: element3, // 指定触发动画的元素
        start: "top 80%", // 定义触发动画的滚动位置
        end: "top 20%",
        toggleActions: "play none none reset", // 动画播放方式
        scrub: true,
      },
    });
  },

  methods: {
    openDialog(index) {
      this.outerVisible = true;
      this.videoUrl = this.videoUrls[index];
      setTimeout(() => {
        this.playVideo();
      }, 500);
    },
    closeDialog() {
      this.outerVisible = false;
      this.stopVideo();
    },
    playVideo() {
      console.log("播放视频");
      this.$refs.videoPlayer.play();
    },
    stopVideo() {
      this.$refs.videoPlayer.pause();
      this.$refs.videoPlayer.currentTime = 0;
    },
    navigateToTarget(index) {
      console.log("点击跳转");
      const param = index; // 设置参数值
      this.$router.push({ path: "/case", query: { id: param } });
    },
  },
};
</script>
<style lang="scss">
.home-container {
  width: 100%;
  max-width: 100%;
  height: auto;

  // background-color:var(--red-color);
  .main {
    .main-bac {
      opacity: 0.5;
      position: absolute;
      top: 900px;
      width: 100%;
      height: 6050px;
      background-image: url("../assets/img/资源 1.svg");
      background-repeat: no-repeat;
      background-size: 110%;
      background-position: center center;
      -webkit-filter: blur(14px);
      -moz-filter: blur(14px);
      -o-filter: blur(14px);
      -ms-filter: blur(14px);
      filter: blur(14px);
      z-index: -1;
    }

    overflow: hidden;
    width: 100%;
    // height: 7000px;

    .top-img {
      min-width: 1150px;
      width: 100%;
      height: auto;
      // background-color: pink;
      // z-index: 99;
      // background-image: url("../assets/img/A-home.webp");

      overflow: hidden;

      img {
        width: 100%;
        height: auto;
        // display: block;
        // margin-left: -20%;
        // height: 105%;
      }
    }

    .main-data {
      // 第一部分css
      .p1 .p2 {
        opacity: 0;
      }

      .p1 {
        span {
          @media (max-width: 768px) {
            font-size: 16px;
            font-weight: 700;
          }
        }
      }

      .p2-title {
        width: 50rem !important;
        margin: 30px auto;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 2rem;
        text-align: left;
        font-size: 1.25rem;
        font-family: "SF Pro Display Regular";

        @media (max-width: 768px) {
          width: 100% !important;
          padding: 10px;
        }
      }

      .p2-col {
        font-size: 2rem;
        color: red;
        margin-right: 0.5rem;
      }

      .p2-col-1 {
        font-size: 1.25rem;
      }

      @media (max-width: 768px) {
        .p1 {
          min-width: auto;
          font-size: 12px;
        }

        .p2 {
          min-width: auto;
          font-size: 12px;
        }
      }

      .main-ability1 {
        width: 1150px;
        height: 550px;
        margin: 70px auto 127px;
        display: flex;
        justify-content: space-between;

        @media (max-width: 768px) {
          display: block;
          width: 100%;
          height: 100%;
          margin-bottom: 0;

          .ablity-li {
            width: 100% !important;
          }

          .ablity-bot-text {
            margin-bottom: 10px !important;

            p {
              text-align: center !important;
            }
          }
        }

        // opacity: 0;
        .ablity-li {
          width: 31%;
          height: 100%;
          box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.07);

          // 上半部分图片
          .ablity-li-img {
            position: relative;
            width: 100%;
            height: 70%;
            overflow: hidden;

            img {
              width: 65%;
              height: auto;
              margin-top: 74px;
            }

            .img-3 {
              width: 48%;
              margin-top: 43px;
            }

            .ablity-li-title {
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 0;
              overflow: hidden;
              background-color: rgb(231, 22, 41);
              transition: 0.5s;

              p {
                float: left;
                line-height: 50px;
                margin-left: 20px;
              }

              img {
                float: right;
                width: 100px;
                height: 100%;
                background-color: rgba(157, 209, 255, 0.611);
              }
            }
          }

          // 底部文字
          .ablity-bot {
            width: 100%;
            height: 20%;
            // background-color: aliceblue;
            // margin-top: 5px;
            display: flex;

            .ablity-bot-text {
              margin: 0 auto;
              width: 84%;
              height: 100%;

              p {
                text-align: left;
                margin: 2px;
              }

              .text1 {
                font-size: 23px;
              }

              .text2 {
                margin-top: 15px;
                font-size: 0.95rem;
              }
            }
          }
        }
      }

      // 第二部分css
      .main-ability2-w {
        width: 100%;
        height: auto;
        background-color: rgb(246, 247, 251);
        padding-top: 10px;
        border: 1px solid rgba(255, 255, 255, 0);
        margin-bottom: 1px;

        .main-ability2 {
          width: 60%;
          height: 650px;
          margin: 70px auto;

          @media (max-width: 768px) {
            width: 100%;
            height: 100%;
          }

          .ablity2-data {
            width: 100%;
            height: 50%;
            margin-top: 8px;
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;

            @media (max-width: 768px) {
              flex-direction: column;
            }

            .ablity2-data-li {
              width: 100%;
              height: 100%;
              cursor: pointer;
              transition: 0.5s;

              margin-right: 5px;

              @media (max-width: 768px) {
                width: 100%;
              }

              .ab2-img {
                width: 100%;
                height: 75%;
                overflow: hidden;

                img {
                  transition: 0.5s;
                  width: 100%;
                  height: auto;
                }
              }

              .ablity2-bot {
                width: 100%;
                height: 35%;
                transition: 0.2s;
                background: rgb(255, 255, 255);

                .ablity2-bot-text {
                  width: 85%;
                  height: 100%;
                  float: left;
                  padding: 0 30px;

                  p {
                    text-align: left;
                    margin: 4px 0;
                  }

                  .text1 {
                    font-size: 18px;
                    margin-bottom: 0;
                  }

                  .text2 {
                    margin-top: 7px;
                    font-size: 0.8rem;
                  }
                }

                i {
                  float: right;
                  font-size: 29px;
                  line-height: 65px;
                  margin-right: 20px;
                }
              }

              &:hover {
                .ablity2-bot {
                  background-image: linear-gradient(
                    45deg,
                    #ff0000 0%,
                    #ff0000 55%,
                    #f79d9d 90%
                  );
                  background-position: 100% 0;
                  background-size: 200% 200%;

                  .ablity2-bot-text {
                    color: rgb(255, 255, 255);
                  }

                  i {
                    color: #fff;
                  }

                  // background: linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,89,89,1) 46%, rgba(255,202,202,1) 100%);
                }

                .ab2-img {
                  img {
                    transform: scale(1.2);
                  }
                }
              }
            }
          }
        }
      }

      // 第三部分
      .main-ability3 {
        width: 1150px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        margin-bottom: 5px;

        @media (max-width: 768px) {
          flex-direction: column;
          width: 100%;
        }

        .ability3-left {
          position: relative;
          width: 570px;
          overflow: hidden;

          &:hover {
            .mask-1 {
              transform: scale(1.1);
              transition: 0.5s all;
            }
          }

          img {
            width: 100%;
            height: 100%;
            opacity: 0.9;
          }

          .mask-1 {
            transition: 0.5s all;
          }

          .mask-2 {
            position: absolute;
            top: 0;
            left: 0;
            height: 315px;

            @media (max-width: 768px) {
              height: 100%;
            }
          }

          @media (max-width: 768px) {
            width: 100%;

            .mask-1 {
              transform: none;
            }
          }
        }

        .ability3-right {
          width: 570px;
          position: relative;

          &:hover {
            .bgc-hui .red-animation {
              width: 100%;
            }
          }

          @media (max-width: 768px) {
            width: 100%;
            padding: 10px;
          }

          h3 {
            margin-top: 0;
            padding-top: 0;
            text-align: left;
            letter-spacing: 2px;
          }

          .right-desc {
            display: flex;
            justify-content: space-between;
            font-size: 16px;

            .red-font {
              border: 1px solid red;
              border-radius: 3px;
              color: red;
              font-weight: 700;
              margin-right: 10px;
              font-size: 12px;
              padding: 2px;
            }

            .hui-font {
              padding: 2px;
              background-color: #e7e7e7;
              font-size: 12px;
              color: #111111;
            }

            .hui-time {
              color: #bbbbbb;
              font-size: 16px;
              padding-right: 10px;
            }
          }

          .font-desc {
            color: #111111;
            letter-spacing: 1px;
            line-height: 27px;
            text-indent: 2em;
            text-align: left;
            font-size: 0.85rem;
            font-family: "SF Pro Display Regular";
          }

          .bgc-hui {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 3px;
            background-color: #e6e6e6;

            .red-animation {
              height: 3px;
              width: 100px;
              background-color: red;
              transition: width 1s ease;
            }

            @media (max-width: 768px) {
              display: none;
            }
          }

          .red-href {
            position: absolute;
            bottom: 5px;
            right: 0;

            a {
              color: red;
              text-decoration: none;
              font-weight: 700;
              font-size: 15px;

              @media (max-width: 768px) {
                font-size: 10px;
              }
            }

            @media (max-width: 768px) {
              bottom: -5px;
            }
          }
        }
      }

      .fw-p1 {
        margin-top: 118px;
      }

      .main-ability4 {
        width: 1150px;
        height: 300px;
        margin: 10px auto;
        margin-bottom: 80px;
        background-image: url("../assets/img/服务流程图.svg");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;

        @media (max-width: 768px) {
          width: 80%;
          height: 140px;
          margin: 0 auto;
        }
      }

      // 第五部分

      .main-ability5 {
        width: 100%;
        height: 1860px;
        background-color: rgb(0, 14, 20);
        position: relative;

        @media (max-width: 768px) {
          height: 100%;
        }

        .ability5-bot {
          color: #fff;
        }

        .box-3d {
          min-width: 1920px;
          width: 100%;
          height: 850px;

          @media (max-width: 768px) {
            height: 400px;
            min-width: auto;
            margin-top: 0;
          }

          // background-color: rgb(62, 61, 61);
          .btn-3dbox {
            position: relative;
            width: 100%;
            height: 400px;
            // background-color: rgba(255, 255, 255, 0.242);
            z-index: 2;
            display: flex;
            padding: 0 128px;

            @media (max-width: 768px) {
              padding: 0;
            }

            // transform: perspective(700px) rotateX(23deg) rotateZ(-9deg)
            //     rotateY(15deg) scale3d(1, 1, -0.9);
            a {
              width: 450px;
              height: 80%;
              margin: auto;

              @media (max-width: 768px) {
                width: 80%;
                height: 29%;
                margin: 30px 10px;
                margin-top: 6rem;
              }

              .dbtn-box {
                position: relative;

                border-radius: 10px;
                overflow: hidden;
                transition: 0.2s;
                width: 100%;
                height: 100%;
                cursor: pointer;

                // box-shadow: 0 0 10px 2px rgba(83, 240, 193, 0.8);
                .bl {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  -webkit-backdrop-filter: saturate(150%) blur(0.808333rem);
                  backdrop-filter: saturate(150%) blur(0.308333rem);
                }

                img {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }

              &:nth-child(3) {
                pointer-events: none;
              }
            }

            .btn-box1 {
              border: 2px solid rgba(83, 240, 193, 0.8);

              &:hover {
                box-shadow: 0 0 31px 10px rgba(83, 240, 193, 0.8);
              }

              .bl {
                background-color: rgba(83, 240, 193, 0.45);
              }
            }

            .btn-box2 {
              border: 2px solid rgba(217, 142, 255, 0.8);

              &:hover {
                box-shadow: 0 0 31px 10px rgba(142, 69, 246, 0.8);
              }

              .bl {
                // background: linear-gradient(
                //   to bottom,
                //   #00ff0000,
                //   rgba(142,69,246, 0.242)
                // );
                background-color: rgba(142, 69, 246, 0.45);
              }
            }

            .btn-box3 {
              border: 2px solid rgba(68, 234, 255, 0.8);

              &:hover {
                box-shadow: 0 0 31px 10px rgba(0, 166, 224, 0.8);
              }

              .bl {
                // background: linear-gradient(
                //   to bottom,
                //   #00ff0000,
                //   rgba(0,166,224, 0.242)
                // );
                background-color: rgba(0, 166, 224, 0.45);
              }
            }
          }

          .Three2 {
            height: 600px;
            margin-top: -13%;
            position: absolute;
            bottom: 0;

            //  pointer-events: none;
            @media (max-width: 768px) {
              margin-top: -66%;
            }
          }
        }

        .ability5-top {
          width: 60%;
          height: auto;
          margin: auto;
          color: aliceblue;
          text-align: left;
          padding-top: 85px;

          @media (max-width: 768px) {
            margin: 0 2rem;
          }

          .text1 {
            font-size: 40px;
            margin: 0;

            @media (max-width: 768px) {
              font-size: 25px;
            }
          }

          .text2 {
            //font-family: "SF Pro Display Regular";
            font-size: 1.25rem;
          }
        }

        .ability5-main {
          width: 100%;
          height: 665px;
          // background-color: pink;
          display: flex;
          flex-direction: row;
          margin-top: 60px;

          @media (max-width: 768px) {
            display: block;
            height: 100%;
          }

          .ability5-main-li {
            flex: 1;
            height: 100%;
            margin-left: 10px;
            // border: 1px solid red;
            background-position: center;
            background-size: cover;
            background-repeat: none;
            transition: 0.7s;
            overflow: hidden;

            @media (max-width: 768px) {
              width: 100%;
              margin-left: 0;
            }

            // margin-left: 10px;
            .ability5-img {
              border: 0.5px solid rgb(201, 184, 136);
              position: relative;
              width: auto;
              height: 505px;
              overflow: hidden;

              @media (max-width: 768px) {
                height: 280px;
              }

              img {
                width: auto;
                height: 100%;
                transition: 0.7s;
                // opacity: 0.75;
                filter: grayscaLe(60%);

                @media (max-width: 768px) {
                  width: 100%;
                }
              }

              .ability5-btn {
                width: 100%;
                height: 100%;
                background-color: rgba(60, 59, 59, 0);
                transition: 0.7s;
                position: absolute;
                display: flex;
                top: 0;
                left: 0;

                img {
                  width: 45px;
                  height: auto;
                  margin: auto;
                  cursor: pointer;
                  opacity: 0;

                  @media (max-width: 768px) {
                    opacity: 1;
                  }
                }
              }
            }

            .ability5-text {
              // color: rgb(255, 255, 255);
              color: rgb(201, 184, 136);
              text-align: left;

              p {
                margin-left: 50px;
              }

              .text1 {
                font-size: 20px;
                margin-top: 35px;
                margin-bottom: 17px;
              }

              .text2 {
                font-size: 12px;
                margin-top: 0;
                //font-family: "SF Pro Display Regular";
              }
            }

            &:hover {
              flex: 3;

              img {
                // opacity:1;
                filter: grayscaLe(0%);
                transform: scale(1.3);
              }

              .ability5-btn {
                // background-color: rgba(60, 59, 59, 0.535);
                // -webkit-backdrop-filter: saturate(150%) blur(0.208333rem);
                // backdrop-filter: saturate(150%) blur(0.208333rem);
                img {
                  opacity: 1;
                }
              }
            }
          }
        }
      }

      // 第六部分 3D部分

      // 第七部分
      // .main-ability7-c{
      //   width: 60%;
      //   display: flex;
      //   margin: auto;
      // }
      .main-ability7 {
        // width: 60%;
        width: 1150px;
        height: auto;
        margin: 60px auto;
        margin-bottom: 128px;

        @media (max-width: 768px) {
          width: 100%;
        }

        img {
          width: 100%;
          height: auto;
        }
      }

      .ain-ability8-w {
        width: 100%;
        min-width: 1920px;
        // height: 100vh;
        background-color: rgb(223, 221, 221);
        background-image: url("../assets/img/LOGO背景_画板_1.webp");
        // background-repeat: no-repeat;
        background-size: 110%;
        background-position: top;
        padding-top: 10px;
        padding-bottom: 290px;

        @media (max-width: 768px) {
          min-width: auto;
          height: 100%;
        }

        .main-ability8 {
          width: 1150px;
          height: 600px;
          min-width: 1050px;
          margin: 70px auto;
          margin-bottom: 0px;

          @media (max-width: 768px) {
            width: 100%;
            height: 180px;
            min-width: auto;
          }

          .ability8-list {
            margin: 0 auto;
            margin-bottom: 35px;
            width: 100%;
            display: flex;
            justify-content: space-evenly;

            @media (max-width: 768px) {
              margin-bottom: 15px;
            }

            .ability8-logo {
              display: inline-block;
              width: 100%;
              height: 117px;
              background-color: aliceblue;
              transition: 0.2s;

              @media (max-width: 768px) {
                margin: 0 5px;
                height: 40px;
              }

              // cursor: pointer;
              img {
                width: 100%;
                height: 100%;
              }

              &:hover {
                transform: scale(1.02);
                box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.1);
              }
            }

            // &:nth-child(2n) {
            //   width: 80%;
            // }
          }
        }
      }
    }
  }
}

.el-dialog__wrapper {
  backdrop-filter: saturate(150%) blur(0.208333rem);
}

.el-dialog {
  background: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
  width: 60% !important;

  @media (max-width: 768px) {
    width: 90% !important;
  }

  .el-dialog__body {
    @media (max-width: 768px) {
      padding: 0;
    }
  }

  video {
    width: 100%;
    height: auto;
    border: 1px solid rgb(201, 184, 136);
  }
}

@media (max-width: 768px) {
  .home-container .main .top-img {
    min-width: auto;
  }
}
</style>
